import { InjectionToken } from '@angular/core';
import { environment } from './../environments/environment';

export const BASE_OMS_URL = new InjectionToken('BaseOMSUrl', {
  factory: () => environment.baseOMSLink,
});
export const BASE_ICM_URL = new InjectionToken('BaseICMUrl', {
  factory: () => environment.baseICMLink,
});
export const SUPPLIERS_SERVICE_URL = new InjectionToken('SuppliersServiceUrl', {
  factory: () => environment.suppliersServiceUrl,
});

export const RETURNS_SERVICE_URL = new InjectionToken('ReturnsServiceUrl', {
  factory: () => environment.returnsServiceUrl,
});

export const RETURN_FAILURES_SERVICE_URL = new InjectionToken(
  'ReturnFailuresServiceUrl',
  {
    factory: () => environment.returnFailuresServiceUrl,
  }
);

export const DISPATCH_FAILURE_SERVICE_URL = new InjectionToken(
  'DispatchesServiceUrl',
  {
    factory: () => environment.dispatchFailuresServiceUrl,
  }
);

export const INVOICE_SEARCH_SERVICE_URL = new InjectionToken(
  'InvoiceSearchServiceUrl',
  {
    factory: () => environment.invoiceSearchServiceUrl,
  }
);

export const SPECIALS_SERVICE_URL = new InjectionToken('SpecialsServiceUrl', {
  factory: () => environment.specialsServiceUrl,
});

export const PAYMENT_TERMS_CHECKER_SERVICE_URL = new InjectionToken(
  'PaymentTermsCheckerServiceUrl',
  {
    factory: () => environment.paymentTermsCheckerServiceUrl,
  }
);

export const SOS_EMAIL_SERVICE_URL = new InjectionToken('sosEmailServiceUrl', {
  factory: () => environment.sosEmailServiceUrl,
});

export const PRICE_CHECKER_SERVICE_URL = new InjectionToken(
  'priceCheckerServiceUrl',
  {
    factory: () => environment.priceCheckerServiceUrl,
  }
);

export const SHIPPING_ADDRESSES_URL = new InjectionToken(
  'priceCheckerServiceUrl',
  {
    factory: () => environment.shippingAddressesUrl,
  }
);

export const CONNECTION_STATUS_CHECK_URL = new InjectionToken(
  'connectionStatusCheckUrl',
  {
    factory: () => environment.connectionStatusCheckUrl,
  }
);

export const SCSN_CUSTOMERS_URL = new InjectionToken('scsnCustomersUrl', {
  factory: () => environment.scsnCustomersUrl,
});

export const INDI_CUSTOMERS_URL = new InjectionToken('indiCustomersUrl', {
  factory: () => environment.indiCustomersUrl,
});
