// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  allowedResourceServerUrls: ['http://localhost:8080', 'http://localhost:8081'],
  baseOMSLink: 'https://indi-oms-eks.acc.indicloud.net/omt/app/',
  baseICMLink:
    'https://webshop-10.acc.indicloud.net/INTERSHOP/web/WFS/org-Site/nl_NL/webshop/EUR/',
  suppliersServiceUrl: 'http://localhost:8080/suppliers',
  returnsServiceUrl: 'http://localhost:8080/returns',
  returnFailuresServiceUrl: 'http://localhost:8080/returns/faileds',
  dispatchFailuresServiceUrl: 'http://localhost:8080/dispatches/faileds',
  invoiceSearchServiceUrl: 'http://localhost:8080/invoices',
  specialsServiceUrl: 'http://localhost:8080/specials',
  paymentTermsCheckerServiceUrl: 'http://localhost:8080/payment-terms',
  sosEmailServiceUrl: 'http://localhost:8080/sos',
  priceCheckerServiceUrl: 'http://localhost:8080/prices',
  shippingAddressesUrl: 'http://localhost:8080/shipping-addresses',
  connectionStatusCheckUrl: 'http://localhost:8080/q/health',
  scsnCustomersUrl: 'http://localhost:8080/customers',
  //these two sometimes have to run simultaneously (for the scsn customer), so the port is different
  indiCustomersUrl: 'http://localhost:8081/indi-customers',
};
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
